import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../assests/css/Contact.css";
import { Container } from "react-bootstrap";
import { useRef } from "react";

import emailjs from "@emailjs/browser";

const Contact = () => {
  const [validated, setValidated] = useState(false);
  const form1 = useRef();

  const handleSubmit = (event) => {
    const config = {
      Host: "smtp.elasticemail.com",
      port: 2525,
      Username: "menaemadorai@gmail.com",
      Password: "CB25610056ED5C33F3AF1726D43C6BFB0F29",
      // SecureToken:"fdfe9485-20ce-4e63-9578-75287d6503ca",
      To: "emadmonica65@gmail.com",
      From: data.email,
      Subject: data.subject,
      Body: data.name + "\n" + data.mssage,
    };
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log(config);
    } else {
      event.preventDefault();
      emailjs
        .sendForm(
          "service_38evy27",
          "template_1x0ralm",
          form1.current,
          "user_Qdr9KNtFvRj7X19Pg2l5x"
        )
        .then(
          (result) => {
            alert("email sent successfuly");
            console.log(form);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }

    setValidated(true);
  };

  const [data, setData] = useState({});
  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <section id="Contact">
        <div className="layout"></div>
        <Container>
          <h1 className="contact_header">تواصل معنا</h1>
          <h1 className="contact_sub_header">
            من فضلك إملأ الحقول الأتية لتصلنا رسالتك
          </h1>

          <div
            className="row ordercol"
            style={{ position: "relative", padding: "20px" }}
          >
            <div className="col-md-12 col-lg-12 col-sm-12 form ">
              <Form
                ref={form1}
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="Form_test"
              >
                <Row className="mb-3">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom01"
                    data-aos="fade-right"
                    data-aos-duration="500"
                  >
                    <Form.Label className="form_label">اسمك بالكامل</Form.Label>
                    <Form.Control
                      className="form_input"
                      required
                      type="text"
                      name="name"
                      onChange={updateData}
                      placeholder="إسمك بالكامل"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom02"
                    data-aos="fade-right"
                    data-aos-duration="500"
                  >
                    <Form.Label className="form_label">
                      بريدك الإلكترونى
                    </Form.Label>
                    <Form.Control
                      className="form_input"
                      required
                      type="email"
                      name="email"
                      onChange={updateData}
                      placeholder="بريدك الإلكترونى"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom01"
                    data-aos="fade-right"
                    data-aos-duration="500"
                  >
                    <Form.Label className="form_label">
                      عنوان الموضوع
                    </Form.Label>

                    <Form.Control
                      className="form_input"
                      required
                      type="text"
                      name="subject"
                      onChange={updateData}
                      placeholder="عنوان الموضوع"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom01"
                    data-aos="fade-right"
                    data-aos-duration="500"
                  >
                    <Form.Label className="form_label">رسالتك</Form.Label>
                    <Form.Control
                      className="form_input_textarea"
                      onChange={updateData}
                      name="mssage"
                      required
                      as="textarea"
                      rows={5}
                      cols={7}
                      placeholder="قم بوضع إقتراحك او طلبك او ما تريد هنا"
                    />
                  </Form.Group>
                </Row>
                <Button
                  variant="light"
                  type="submit"
                  className="submmit "
                  data-aos="fade-right"
                  data-aos-duration="500"
                >
                  <span className="send">إرسال</span>
                </Button>
              </Form>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Contact;
