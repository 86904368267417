import React from "react";
import "../assests/css/services.css";
import { Container } from "react-bootstrap";

const Services = () => {
  return (
    <>
      <section id="Services Container">
        <Container>
          <h1 className="Service_header" data-aos="zoom-in">
            خدمات المركز
          </h1>
          <br></br>
          <Container
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 test special-test">
                <div>
                  <div
                    className="box special-box"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <div className="name special-name">
                      البحث في المفاهيم والأهداف التي يتبناها المركز، عبر
                      مجموعة من أفضل الباحثين الدارسين لعلوم الإنسان
                      "الأنثروبولوجيا" وعلوم الاجتماع والتاريخ والفلسفة، من خلال دراسات
                      مفصلة لا تغفل السياقات
                    </div>
                    <div className="one0"></div>
                    <div className="two0"></div>
                    <div className="three0"></div>
                    <div className="four0"></div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 col-sm-12 test"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <div className="box">
                    <div className="name">
                    تصميم وتنفيذ الدراسات واستطلاعات الرأي المتعلقة بمجالات الرخاء الاجتماعي
                    </div>
                    <div className="one1"></div>
                    <div className="two1"></div>
                    <div className="three1"></div>
                    <div className="four1"></div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 col-sm-12 test"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <div className="box">
                    <div className="name">
                      تقديم البرامج والورش التعريفية بمفهوم الرخاء الاجتماعي
                      وسبل الارتقاء به
                    </div>
                    <div className="one2"></div>
                    <div className="two2"></div>
                    <div className="three2"></div>
                    <div className="four2"></div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 col-sm-12 test"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div>
                  <div className="box">
                    <div className="name">
                      تقديم دورات متخصصة في قياس الرخاء الاجتماعي باستخدام
                      المؤشرات الخاصة بالمركز
                    </div>
                    <div className="one3"></div>
                    <div className="two3"></div>
                    <div className="three3"></div>
                    <div className="four3"></div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default Services;
