import React from "react";
import "../assests/css/MyCarousel.css";

const MyCarousel = () => {
  window.addEventListener("load", function (e) {
    var container = document.querySelector(".my-carousel-container");
    var middle =
      container.children[Math.floor((container.children.length - 1) / 2)];
    container.scrollLeft =
      middle.offsetLeft + middle.offsetWidth / 2 - container.offsetWidth / 2;
  });

  return (
    <div className="my-carousel-container" id="mcc">
      <div
        className="my-carousel-card"
        data-aos="flip-down"
        data-aos-duration="1500"
      >
        <p className="first-heading special-heading2">!إلى أين نريد أن نصل</p>
        <p className="first-paragraph">
          تحديد معنى الرخاء الاجتماعي بمفهومه الشامل الذي يشمل الجانب القِيمي
          والإنساني والجانب المادي
        </p>
      </div>
      <div
        className="my-carousel-card"
        data-aos="flip-down"
        data-aos-duration="1000"
      >
        <p className="first-heading special-heading1">كيف يمكننا الوصول ؟</p>
        <p className="first-paragraph">
          تحديد مخطط السير الذي يساعد على الوصول إلى الوجهة العالمية المنشودة
          (الرخاء الاجتماعي الشامل){" "}
        </p>
      </div>
      <div
        className="my-carousel-card"
        data-aos="flip-down"
        data-aos-duration="500"
      >
        <p className="first-heading special-heading">
          !ما هي الجهات المعنية 
        </p>
        <p className="first-paragraph">
          تسهيل عملية صنع القرار للقيادات والجهات المعنية بتحقيق التنمية الشاملة
          وتعزيز الرخاء الاجتماعي على الصعيد الوطني والإقليمي والعالمي{" "}
        </p>
      </div>
    </div>
  );
};

export default MyCarousel;
