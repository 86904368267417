import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import About from "./components/About";
import Message from "./components/Message";
import MajorFunctions from "./components/MajorFunctions";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MyCarousel from "./components/MyCarousel";
import NavbarEdited from "./components/NavbarEdited";
import AOS from "aos";
import "aos/dist/aos.css";
import HomeEdited from "./components/HomeEdited";
import PublicationsEdited from "./components/PublicationsEdited";

function App() {
  AOS.init();
  return (
    <>
      <NavbarEdited />
      <HomeEdited />
      <MyCarousel />
      <About />
      <Message />
      <br></br>
      <MajorFunctions />
      <br></br>
      <Services />
      {/* <Publications /> */}
      <PublicationsEdited />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Contact />
      <br></br>
      <Footer />
    </>
  );
}

export default App;
