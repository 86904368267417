import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../assests/css/Footer.css";
import icon1 from "../assests/Images/footer_email.png"; // gives image path

function Footer() {
  return (
    <section id="Footer" style={{ padding: "20px", direction: "rtl" }}>
      <Container>
        <Row>
          <Col
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="col1">
              <h1 className="footer_header">مركز دراسات الرخاء الاجتماعي</h1>
              <h1 className="footer_sub_header">
                رؤيتنا هي الإرتقاء بمستوى الرخاء الاجتماعي في المنطقة ،
                وفقا لمنهجية علمية متناسبة مع حضارة شعوبها وثقافتها.
              </h1>
            </div>
          </Col>
          <Col
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <Container>
              <div className="row " style={{ justifyContent: "center" }}>
                <div className="footer_header2 col-12 ">
                  تواصل معنا مباشرةً على البريد الإلكتروني للمركز
                </div>
                <div
                  className="col-md-12 col-lg-12 col-sm-12 col-xs-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div className="row fotter_style">
                    <div className="col-3">
                      <a href="mailto:info@cspsstudies.org">
                        <img src={icon1} alt="email" />
                      </a>
                    </div>
                    <div className="col-9 email_pos">
                      <a
                        href="mailto:info@cspsstudies.org"
                        className="footer_icon"
                      >
                        info@cspsstudies.org
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Footer;
