// import React from "react";
// import "../assests/css/HomeEdited.css";
// import Carousel from "react-bootstrap/Carousel";
// import LogoImg from "../assests/Images/siteLogo.png";

// const HomeEdited = () => {
//   return (
//     <Carousel autoPlay interval="4000" touch="false" pause="false">
//       <Carousel.Item className="home-main">
//         <div className="home-content-container">
//           <div className="home-logo">
//             <img
//               src={LogoImg}
//               className="home-logo-img"
//               alt="HomePageImage"
//             ></img>
//           </div>
//           <div className="home-text">
//             <p className="first-paragraph-home">
//               يعمل مركز دراسات الرخاء الاجتماعي بشكل علمي منهجي للمساهمة في ردم
//               الفجوة بين الهدف المنشود{" "}
//             </p>
//             <p className="second-paragraph-home">لتحقيق الرخاء المستدام</p>
//             <p className="third-paragraph-home">
//               وبين الآليات وخطة السير التي تشمل الاتجاهات والمهام والمسؤوليات
//               وفق معايير قابلة للقياس؛ لتعزيز قدرة المجتمعات والدول على الوصول
//               إلى التنمية الشاملة والرخاء الاجتماعي
//             </p>
//           </div>
//         </div>
//       </Carousel.Item>
//       <Carousel.Item className="home-main2">
//         <div className="home-content-container">
//           <div className="home-logo">
//             <img
//               src={LogoImg}
//               className="home-logo-img"
//               alt="HomePageImage"
//             ></img>
//           </div>
//           <div className="home-text">
//             <p className="first-paragraph-home">
//               يعمل مركز دراسات الرخاء الاجتماعي بشكل علمي منهجي للمساهمة في ردم
//               الفجوة بين الهدف المنشود{" "}
//             </p>
//             <p className="second-paragraph-home">لتحقيق الرخاء المستدام</p>
//             <p className="third-paragraph-home">
//               وبين الآليات وخطة السير التي تشمل الاتجاهات والمهام والمسؤوليات
//               وفق معايير قابلة للقياس؛ لتعزيز قدرة المجتمعات والدول على الوصول
//               إلى التنمية الشاملة والرخاء الاجتماعي
//             </p>
//           </div>
//         </div>
//       </Carousel.Item>
//       <Carousel.Item className="home-main3">
//         <div className="home-content-container">
//           <div className="home-logo">
//             <img
//               src={LogoImg}
//               className="home-logo-img"
//               alt="HomePageImage"
//             ></img>
//           </div>
//           <div className="home-text">
//             <p className="first-paragraph-home">
//               يعمل مركز دراسات الرخاء الاجتماعي بشكل علمي منهجي للمساهمة في ردم
//               الفجوة بين الهدف المنشود{" "}
//             </p>
//             <p className="second-paragraph-home">لتحقيق الرخاء المستدام</p>
//             <p className="third-paragraph-home">
//               وبين الآليات وخطة السير التي تشمل الاتجاهات والمهام والمسؤوليات
//               وفق معايير قابلة للقياس؛ لتعزيز قدرة المجتمعات والدول على الوصول
//               إلى التنمية الشاملة والرخاء الاجتماعي
//             </p>
//           </div>
//         </div>
//       </Carousel.Item>
//     </Carousel>
//   );
// };

// export default HomeEdited;

import React from "react";
import "../assests/css/HomeEdited.css";
import LogoImg from "../assests/Images/siteLogo.png";

const HomeEdited = () => {
  // let homeImage = document.querySelector('.home-main');
  // let imagesArr = ['../assests/Images/HomeBackground.jpg','../assests/Images/image3.jpg','../assests/Images/images.jfif','../assests/Images/images2.jfif'];

  // homeImage.style.backgroundImage = imagesArr[];

  // console.log(homeImage);

  return (
    <section id="Home">
      <div className="home-main img-fluid">
        <div className="home-content-container">
          <div className="home-logo">
            <img
              src={LogoImg}
              className="home-logo-img"
              alt="HomePageImage"
            ></img>
          </div>

          <div className="home-text">
            <p className="first-paragraph-home">
              يعمل مركز دراسات الرخاء الاجتماعي بشكل علمي منهجي للمساهمة في ردم
              الفجوة بين الهدف المنشود{" "}
            </p>
            <p className="second-paragraph-home">لتحقيق الرخاء المستدام</p>
            <p className="third-paragraph-home">
              وبين الآليات وخطة السير التي تشمل الاتجاهات والمهام والمسؤوليات
              وفق معايير قابلة للقياس؛ لتعزيز قدرة المجتمعات والدول على الوصول
              إلى التنمية الشاملة والرخاء الاجتماعي
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeEdited;
