import React from "react";
import { useState } from "react";
import "../assests/css/NavbarEdited.css";
import { AiOutlineMenu } from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";
import Logo from "../assests/icons/NewLogo.png";

const NavbarEdited = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <section id = "nav">
      <div className="navbarr">
        <div className="open" onClick={toggleMenu}>
          {openMenu ? <TfiClose /> : <AiOutlineMenu />}
        </div>
        <div className="navbar-content">
          <div className="image">
            <a href="#Home">
              <img src={Logo} className="img" alt=""></img>
            </a>
          </div>
          <div className="links">
            <a href="#Home">الرئيسية</a>
            <a href="#About">من نحن</a>
            <a href="#Services Container">خدماتنا</a>
            <a href="#Major_functions">أهدافنا</a>
            <a href="#Message">رسالتنا</a>
            <a href="#PublicationsEdited">إصدارات المركز</a>
            <a href="#Contact">تواصل معنا</a>
          </div>
        </div>
      </div>

      <div className="drop-down">
        {openMenu ? (
          <div className="links-drop-down">
            <a href="#Home">الرئيسية</a>
            <a href="#About">من نحن</a>
            <a href="#Services Container">خدماتنا</a>
            <a href="#Major_functions">أهدافنا</a>
            <a href="#Message">رسالتنا</a>
            <a href="#Publications">إصدارات المركز</a>
            <a href="#Contact">تواصل معنا</a>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default NavbarEdited;
