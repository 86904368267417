import React from "react";
import Card from "react-bootstrap/Card";

import "../assests/css/Major_functions.css";
import { Container } from "react-bootstrap";
import image_card1 from "../assests/Images/الفردو المجتمع.png"; // gives image path
import image_card2 from "../assests/Images/التنوع الثقافى.png";
import image_card3 from "../assests/Images/التعليم.png";
import image_card4 from "../assests/Images/المشاركة و المجتمع.png";
import image_card5 from "../assests/Images/سياحة قانونية.png";
import image_card6 from "../assests/Images/تعزيز الفنون والثقافي.png";

const MajorFunctions = () => {
  return (
    <>
      <section id="Major_functions">
        <Container>
          <div
            className="Major_header"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            أهداف المركز
          </div>
          <div
            className="sub_header"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            لأن بلداننا تستحق الأفضل
          </div>
          <div className="row">
            <br></br>
            <div className="col-md-4 col-sm-12 mt-5 p-55 _order6">
              <Card
                className="Card render fitt"
                data-aos="flip-right"
                data-aos-duration="1000"
              >
                <Card.Body className="card_body">
                  <Card.Title className="card_title">
                    <img
                      src={image_card6}
                      alt="تعزيز الفنون والثقافي"
                      className="svg-img"
                    />
                  </Card.Title>
                  <br></br>
                  <Card.Subtitle className="mb-2">
                    <p className="title">تعزيز دور الفنون و الثقافة</p>
                    <Card.Text className="card_text">
                      ابراز دور الفنون والتراث وتذوق الجمال في تعزيز قيم الرخاء
                      الاجتماعي، وتحفيز دور الهوية الوطنية والموروث الشعبي ضمن
                      مفهوم المواطنة العالمية الجامعة لمختلف المجتمعات
                    </Card.Text>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 col-sm-12 mt-5 p-55 _order3">
              <Card
                className="Card render fitt"
                data-aos="flip-down"
                data-aos-duration="1000"
              >
                <Card.Body className="card_body">
                  <Card.Title className="card_title">
                    <img
                      src={image_card2}
                      alt="التنوع الثقافية"
                      className="svg-img"
                    />
                  </Card.Title>
                  <br></br>
                  <Card.Subtitle className="mb-2">
                    <p className="title">التنوع الثقافي</p>
                    <Card.Text className="card_text">
                      سبر مفاهيم التعدد الثقافي والتعايش وتقبل الآخر، وتعزيز
                      وشائج العمل المشترك ضمن المجتمع الواحد وعبر المجتمعات تحت
                      مظلة مستقبل مشترك يتسم بالرخاء{" "}
                    </Card.Text>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 col-sm-12 mt-5 p-55 _order1" >
              <Card
                className="Card render fitt"
                data-aos="flip-left"
                data-aos-duration="1000"
              >
                <Card.Body className="card_body">
                  <Card.Title className="card_title">
                    <img src={image_card3} alt="التعليم" className="svg-img" />
                  </Card.Title>
                  <br></br>
                  <Card.Subtitle className="mb-2">
                    <p className="title">التعليم</p>
                    <Card.Text className="card_text">
                      تعزيز مفهوم التعليم بشقيه المعرفي والتربوي، ودوره في تأصيل
                      القيم الإنسانية وتمكين الأفراد على كسب المفاهيم والمعارف
                      والمهارات والقدرات اللازمة لبناء مجتمعات أكثر رخاء
                    </Card.Text>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>      
            <div className="col-md-4 col-sm-12 mt-5 p-55 _order2">
              <Card
                className="Card render fitt"
                data-aos="flip-right"
                data-aos-duration="1000"
              >
                <Card.Body className="card_body">
                  <Card.Title className="card_title">
                    <img
                      src={image_card1}
                      alt="تعزيز الروابط الأساسية"
                      className="svg-img special-svg"
                    />
                  </Card.Title>
                  <br></br>
                  <Card.Subtitle className="mb-2">
                    <p className="title"> تعزيز الروابط الأساسية</p>
                    <Card.Text className="card_text">
                      بناء روابط ثقة وتعزيزها بين الفرد والمؤسسات والمجتمع؛ من
                      أجل تكامل الأدوار نحو رخاء مستدام
                    </Card.Text>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 col-sm-12 mt-5 p-55 _order4">
              <Card
                className="Card render fitt"
                data-aos="flip-down"
                data-aos-duration="1000"
              >
                <Card.Body className="card_body">
                  <Card.Title className="card_title">
                    <img
                      src={image_card4}
                      alt="المشاركة الاجتماعية"
                      className="svg-img"
                    />
                  </Card.Title>
                  <br></br>
                  <Card.Subtitle className="mb-2">
                    <p className="title">المشاركة المجتمعية</p>
                    <Card.Text className="card_text">
                      تشجيع مفهوم المشاركة المجتمعية في عملية بناء الرخاء
                      الاجتماعي وخدمة المجتمع
                    </Card.Text>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 col-sm-12 mt-5 p-55 _order5">
              <Card
                className="Card render fitt"
                data-aos="flip-left"
                data-aos-duration="1000"
              >
                <Card.Body className="card_body">
                  <Card.Title className="card_title">
                    <img
                      src={image_card5}
                      alt="سيادة القانون"
                      className="svg-img"
                    />
                  </Card.Title>
                  <br></br>
                  <Card.Subtitle className="mb-2">
                    <p className="title">سيادة القانون</p>
                    <Card.Text className="card_text">
                      تعزيز مفهوم سيادة القانون والعدالة والحوكمة
                    </Card.Text>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MajorFunctions;
