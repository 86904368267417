import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../assests/css/About.css";
import BlckOneImg from "../assests/Images/BlckOneImg.png";
import BlckTwoImg from "../assests/Images/BlckTwoImg.png";
import BlckThreeImg from "../assests/Images/BlckThreeImg.png";

function About() {
  return (
    <section id="About">
      <Container>
        <div className="headerContainer">
          <h1
            className="abtheadline"
            data-aos="fade-down"
            data-aos-anchor-placement="top-bottom"
          >
            لأن قيمة الحياة بجودتها لا بعدد أعوامها
          </h1>
          <h3
            className="abtsecondhead"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {" "}
            دعونا نؤسس حياة أفضل لأبنائنا
          </h3>
        </div>
        <Row className="abtrow">
          <Col
            className="custcolpadabt"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="abtblksparent abtanim">
              <img src={BlckOneImg} className="abtblkimg" alt="balckOne"></img>
              <h4 className="abtblkhdr">دراسات ذات قيمة</h4>
              <p className="abtblkprg">
                دراسات لتقييم مفهوم الرخاء الاجتماعي وتحديد مفهومه بشكل واضح،
                وتحديد سُبل تحقيقه وأساليب القياس الممكنة
              </p>
            </div>
          </Col>
          <Col
            className="custcolpadabt"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <div className="abtblksparent abtscblck abtanim">
              <img
                src={BlckTwoImg}
                className="abtblkimg alt"
                alt="blackOne"
              ></img>
              <h4 className="abtblkhdr">معايير قابلة للقياس</h4>
              <p className="abtblkprg">
                وضع معايير شاملة وقابلة للقياس للرخاء الاجتماعي بمفهومه الشامل
                بشكل أكثر عمقًا من المعايير المعتمدة حاليًا
              </p>
            </div>
          </Col>
          <Col
            className="custcolpadabt"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="abtblksparent abtanim ">
              <img
                src={BlckThreeImg}
                className="abtblkimg alt"
                alt="blackOne"
              ></img>
              <div>
                <h4 className="abtblkhdr">حوارات فكرية</h4>
                <p className="abtblkprg custabtblckprg">
                  إجراء حوارات فكرية على المستويين الوطني والإقليمي للإجابة عن
                  كافة التساؤلات المحورية حول الرخاء الاجتماعي وموقعنا منه،
                  ومتطلبات تحقيقه وسًبل الوصول إليه، وتحديد الأدوار التي تقع على
                  عاتق كلّ من المؤسسات الرسمية ومؤسسات المجتمع المدني والمواطنين
                  للسير قدمًا نحو الرخاء الاجتماعى
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </Container>
    </section>
  );
}

export default About;
