import React from "react";
import "../assests/css/PublicationsEdited.css";
import { HiOutlineDocumentReport } from "react-icons/hi";
import image_card2 from "../assests/Images/اصدارات المركز.png";
import image_card1 from "../assests/Images/مكتبة المراجع.png";
import studies_pdf from "../assests/files/pdfs/دراسة معيارية حو ل الرخا ء الاجتماعي.pdf";
import cit_pdf from "../assests/files/pdfs/المواطنة الإيجابية مدخل للاعتدال والتعايش.pdf";

const PublicationsEdited = () => {
  return (
    <section id="PublicationsEdited">
      <div className="pub-main">
        <h2 className="pub-title" data-aos="fade-up" data-aos-duration="1000">
          إصدارات المركز
        </h2>

        <div className="pub-container">
          <div
            className="pub-card"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="pub-img">
              <img
                src={image_card2}
                alt="إصدارات المركز"
                className="pub-img1"
              />
            </div>

            <div className="pub-content">
              <div className="pub-content1">
                <h2 className="pub-content-title">إصدارات المركز</h2>
                <p className="pub-content-paragraph">
                  للوصول إلى أحدث إصدارات ووثائق المركز
                </p>
              </div>

              <div className="pub-content2">
                <div className="pub-content2-first">
                  <p className="pub-content2-first-p1">:الدراسة الأولى</p>
                  <p className="pub-content2-first-p2">
                    دراسة معيارية حول الرخاء الاجتماعي
                  </p>
                  <a
                    href={studies_pdf}
                    className="pub-content2-first-anchor"
                    target="_blank"
                  >
                    تحميل الملف <HiOutlineDocumentReport className="icon-hi" />
                  </a>
                </div>
                <div className="pub-content2-second">
                  <p className="pub-content2-second-p1">:الدراسة الثانيه</p>
                  <p className="pub-content2-second-p2">
                    المواطنة الإيجابية مدخل للاعتدال والتعايش
                  </p>
                  <a
                    href={cit_pdf}
                    className="pub-content2-second-anchor"
                    target="_blank"
                  >
                    تحميل الملف <HiOutlineDocumentReport className="icon-hi" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="pub-card"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="pub-img">
              <img src={image_card1} alt="مكتبة المراجع" className="pub-img2" />
            </div>

            <div className="pub-content">
              <h2 className="pub-content-title">مكتبة المراجع</h2>
              <p className="pub-content-paragraph">
                للاطلاع على المزيد حول الرخاء الاجتماعي والمفاهيم المرتبطة بها
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PublicationsEdited;
