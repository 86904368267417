import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../assests/css/Message.css";
import Img from "../assests/Images/msgImg.png";

function Message() {
  return (
    <section id="Message">
      <Container className="custcontmsg">
        <Row className="imgovflwmsg">
          <Col
            className="p-0 ordd"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <img src={Img} className="custimgmsg" alt="message"></img>
          </Col>
          <Col className="ordd2">
            <div
              className="colpad"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h4 className="msghdr">رسالة المركز</h4>
              {/* <p className="msgpargrph">
                العمل بشكل علمي للمساهمة في ردم الفجوة بين الهدف المنشود (تحقيق
                الرخاء المستدام)، وبين الآليات وخطة السير التي تشمل الاتجاهات
                والمهام وتوزيع الأدوار بشكل واضح على كافة مكونات المجتمع وفق
                معايير قابلة للقياس؛ لتعزيز قدرة الدول من الوصول إلى التنمية
                الشاملة والرخاء الاجتماعي
              </p> */}
              <p className="msgpargrph">
              العمل بشكل علمي منهجي للمساهمة في ردم الفجوة بين الهدف المنشود (تحقيق الرخاء المستدام)، وبين الآليات وخطة السير التي تشمل الاتجاهات والمهام والمسؤوليات وفق معايير قابلة للقياس؛ لتعزيز قدرة المجتمعات والدول على الوصول إلى التنمية الشاملة والرخاء الاجتماعي
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Message;
